import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'

import 'admin-lte/dist/css/AdminLTE.min.css'
import 'admin-lte/dist/css/skins/skin-blue.min.css'
import 'admin-lte/dist/js/adminlte.min.js'

import '../admin/theme.scss'
import '../admin/coordinates.scss'
import '../admin/location_amenities.scss'
import '../admin/alerts.js'

window['googleMapsLoaded'] = function () {
  //
};
