/**
 * 
 * @param {String} message Message to be displayed
 * @param {String} className Class name of the alert. E.g. 'success', 'info', etc.
 */
function displayAlert(message, className = 'info') {
  $("<div>", {
    html: message + '<button aria-label="Close" class="close" data-dismiss="alert" type="button"><i class="fa fa-times"></i></button>',
    class: "alert alert-" + className
  }).prependTo('#alerts');
}

// expose function globally
window.displayAlert = displayAlert;